
import { DoubleLeftOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { reactive, watch, ref, defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { formatWithIntl } from '@/utils/illuminate';
import type { TableColumn } from '@/components/Table';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import { request } from '@/utils/request';
import ActionModal from './ActionModal.vue';

export default defineComponent({
  name: 'ApproveMgDetail',
  components: { DoubleLeftOutlined, ActionModal },
  setup(props, ctx) {
    const router = useRouter();
    const queryParams = useRoute().query;
    const recordData = reactive<Record<string, string>>({});

    /** -------------------------------------------------表格--------------------------------------------------------- */
    const tableRef = ref<any>(null);
    const tableColumns: TableColumn[] = [
      { title: '行程单号', dataIndex: 'businessNo', width: '220px', align: 'center' },
      { title: '是否跨城', dataIndex: 'hasCrossCity', width: '120px', customRender: ({ text }) => (text ? '是' : '否'), align: 'center' },
      { title: '从家出发', dataIndex: 'departureIsHome', width: '120px', customRender: ({ text }) => (text ? '是' : '否'), align: 'center', slots: { customRender: 'userName' } },
      { title: '在家结束', dataIndex: 'destinationIsHome', width: '120px', customRender: ({ text }) => (text ? '是' : '否'), align: 'center' },
      { title: '行程信息', dataIndex: 'originName', width: '220px', slots: { customRender: 'originName' } },
      { title: '打点情况', dataIndex: 'tagException', width: '120px', align: 'center', slots: { customRender: 'tagException' } },
      { title: '用车事由', dataIndex: 'reasons', width: '100px', customRender: ({ text }) => text || '---', align: 'center' },
      { title: '费用承担部门', dataIndex: 'bearingDepartmentName', customRender: ({ text }) => text || '---', width: '130px', align: 'center' },
      { title: '总里程', dataIndex: 'totalDistanceKm', width: '100px', align: 'center' },
      { title: '油补金额（元）', dataIndex: 'supplementAmount', width: '100px', customRender: ({ text }) => formatWithIntl(toLocalUnit(text || 0)), align: 'center' },
      { title: '过路费（元）', dataIndex: 'tollAmount', width: '100px', customRender: ({ text }) => formatWithIntl(toLocalUnit(text || 0)), align: 'center' },
      { title: '停车费（元）', dataIndex: 'parkingAmount', width: '100px', customRender: ({ text }) => formatWithIntl(toLocalUnit(text || 0)), align: 'center' },
      { title: '可发放金额（元）', dataIndex: 'availableAmount', width: '100px', customRender: ({ text }) => formatWithIntl(toLocalUnit(text || 0)), align: 'center' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' },
    ];
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      try {
        const params: any = { ...parameter, itineraryApprovalFormId: queryParams.id };

        request.post(createApiUrl('/newlinkSass/itinerary/getItineraryByApproveId'), params)
          .then((res: any) => {
            resolve(res.data);
          })
          .catch((e: Error & any) => message.error(e.message));
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    });

    const onRefreshTable = () => {
      tableRef.value.refresh(true);
    };

    const goBack = () => {
      router.replace({ name: 'benefit.approve.manage' });
    };

    const onDetail = (record: any) => {
      console.log(record);
    };

    const actionModalRef = ref();
    const handleOperate = (type: string, id?: number) => {
      actionModalRef.value.handle(type, id);
    };

    onMounted(() => {
      const record = sessionStorage.getItem('approveRecord');
      if (record) Object.assign(recordData, { ...JSON.parse(record) });
    });

    return {
      recordData,
      tableRef,
      tableColumns,
      loadTableData,
      onDetail,
      goBack,
      onRefreshTable,
      actionModalRef,
      handleOperate,
    };
  },
});
