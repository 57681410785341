
import { defineComponent, reactive, ref, computed, onMounted, nextTick, watch } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { request, useFetchAbleRef } from '@/utils/request';
import { createApiUrl, firstError, isValidateError } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import '@amap/amap-jsapi-types';
import { formatDate } from '@/core/filters';
import ImageViewer from '@/components/Image/index.vue';


type ExpensesSubsidizedType = {
  /** 用车事由 */
  reasons: string;
  /** 出发地是否为家 */
  departureIsHome: boolean;
  /** 目的地是否为家 */
  destinationIsHome: boolean;
  /** 费用承担部门 */
  bearingDepartment: number;
  /** 费用承担部门名称 */
  bearingDepartmentName: number;
  /** 是否产生过路费 */
  hasTollFee: boolean;
  tollInvoiceInfo: Array<{invoiceAmount: number, invoiceNo: string, invoiceUrl: string}>;
  /** 过路费发票 */
  tollVoucher: string;
  /** 过路费金额 */
  tollFee: number;
  /** 是否产生停车费 */
  hasParkingFee: boolean;
  /** 停车费凭证 */
  parkingFeeVoucher: Array<string>;
  /** 停车费金额 */
  parkingFee: number;
  /** 是否跨城用车 */
  hasCrossCity: boolean;
  crossCityVoucher: Array<string>,
  /** 公共交通金额 */
  publicTransportationFee: number;
}

const defaultFormData = {
  id: 0,
  approveStatus: 1,
  reason: '',
  canResubmit: true,
};

export default defineComponent({
  name: 'AddressEditorModal',
  components: { ImageViewer },
  emits: ['complete'],
  setup(props, ctx) {
    const store = useStore();
    const visible = ref(false);
    const submiting = ref(false);
    const record = useFetchAbleRef<any>({ invoiceRecordId: 0, invoiceNo: '' });
    record.get(createApiUrl('/newlinkSass/itinerary/approve/detail'));

    let map: AMap.Map;
    let driving: any;

    const action = ref('approve');

    const formData = ref({
      id: 0,
      approveStatus: 1,
      reason: '',
      canResubmit: true,
    });

    const tableColumns: TableColumn[] = [
      { title: '打点', dataIndex: 'tag', slots: { customRender: 'tag' }, width: 120 },
      { title: '位置信息', dataIndex: 'address', customRender: ({ text }) => text || '---' },
      { title: '打点时间', dataIndex: 'createTime', customRender: ({ text }) => formatDate(text), width: 180 },
      { title: '里程(公里)', dataIndex: 'distanceKm', customRender: ({ text }) => text || '---', width: 120 },
      { title: '其他', dataIndex: 'totalRealAmount', slots: { customRender: 'action' }, width: 180 },
    ];

    watch(() => formData.value.reason, (val: any) => {
      formData.value.reason = val.replace(' ', '');
    });

    const handle = (type: string, id?: number) => {
      visible.value = true;
      action.value = type;
      formData.value.id = id || 0;

      try {
        nextTick(async () => {
          map = new AMap.Map('mapContainer', {
            zoom: 11, // 级别
            center: [116.397514, 39.907749], // 中心点坐标
            viewMode: '3D', // 使用3D视图
          });
          await record.fetch({ params: { id } });

          AMap.plugin('AMap.Driving', () => {
            driving = new AMap.Driving({ map, policy: AMap.DrivingPolicy.LEAST_TIME });
            const { passPoints } = record.value;
            driving.search(
              [passPoints[0].longitude, passPoints[0].latitude],
              [
                passPoints[passPoints.length - 1].longitude,
                passPoints[passPoints.length - 1].latitude,
              ],
              { waypoints: passPoints.slice(1, passPoints.length - 1).map((i: any) => ([i.longitude, i.latitude])) },
            );
          });
        });
      } catch (e) {
        console.log(e);
      }
    };

    /** --------------------------------------------------------------------操作按钮---------------------------------------------------------------- */
    const onCancel = () => {
      formData.value = {
        id: 0,
        approveStatus: 1,
        reason: '',
        canResubmit: true,
      };
      visible.value = false;
      ctx.emit('complete', false);
      map && map.destroy();
    };

    const onSubmit = async () => {
      if (formData.value.approveStatus === 2 && !formData.value.reason) return message.error('请输入驳回原因');
      try {
        await request.post(createApiUrl('/newlinkSass/itinerary/approve/review'), { ...formData.value, userCode: store.state.User.userInfo.account });
      } catch (e: Error & any) {
        return message.error(e.message);
      }

      message.success('提交完成');
      ctx.emit('complete', false);
      visible.value = false;
      formData.value = defaultFormData;
      map && map.destroy();
    };

    /** --------------------------------------------------------------------货拉拉附加信息---------------------------------------------------------------- */
    let expensesSubsidized = reactive<Partial<ExpensesSubsidizedType>>({});
    const expensesSubsidizedFlag = ref(false);
    watch(() => record.value, (newVal: any) => {
      formData.value.reason = record.value.reason || '';
      formData.value.approveStatus = record.value.approveStatus || 1;
      formData.value.canResubmit = record.value.canResubmit || true;
     
      if (newVal && newVal.expensesSubsidized) {
        Object.assign(expensesSubsidized, { ...newVal.expensesSubsidized });
        expensesSubsidizedFlag.value = true;
      } else {
        expensesSubsidizedFlag.value = false;
        expensesSubsidized = {};
      }
    }, { deep: true });

    /** --------------------------------------------------预览图片-------------------------------------------------------------------------------------*/
    const picVisible = ref(false);
    const imageList = ref<Array<string>>([]);
    const checkVoucher = (type: string, data: Array<any>) => {
      picVisible.value = true;
      let list: Array<string> = [];
      if (type === 'tollFee') {
        list = data.map(item => item.invoiceUrl);
      } else {
        list = data || [];
      }
      imageList.value = list;
    };

    return {
      visible,
      action,
      submiting,
      formData,
      record,
      expensesSubsidized,
      expensesSubsidizedFlag,
      checkVoucher,
      picVisible,
      imageList,

      tableColumns,
      onSubmit,
      onCancel,
      handle,
    };
  },
});
