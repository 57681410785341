import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "approveManage-content" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_form = _resolveComponent("app-form")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer approveManage" }, {
    title: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.sldPageTitle('#1890ff', '审批单管理')
      }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_app_form, {
          "search-list": _ctx.searchList,
          "search-data": _ctx.filterParams,
          onHandleSearch: _ctx.onRefreshTable
        }, null, 8, ["search-list", "search-data", "onHandleSearch"]),
        _createVNode(_component_app_table, {
          ref: "tableRef",
          size: "default",
          "row-key": "id",
          "show-pagination": true,
          columns: _ctx.tableColumns,
          data: _ctx.loadTableData,
          scroll: { x:'100%', y: 500 }
        }, {
          userName: _withCtx(({ record }) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, _toDisplayString(record.userName), 1),
              _createElementVNode("div", null, _toDisplayString(record.phone ? `${record.phone.substr(0,3)}****${record.phone.substr(7)}` : '---'), 1)
            ])
          ]),
          action: _withCtx(({ record }) => [
            _createElementVNode("a", {
              href: "#",
              onClick: _withModifiers(($event: any) => (_ctx.onDetail(record)), ["prevent"])
            }, "详情", 8, _hoisted_3)
          ]),
          _: 1
        }, 8, ["columns", "data"])
      ])
    ]),
    _: 1
  }))
}