import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "benefitApproveList__content" }
const _hoisted_2 = { class: "routeTab" }
const _hoisted_3 = { class: "select_info" }
const _hoisted_4 = { class: "select_num" }
const _hoisted_5 = { class: "break-word" }
const _hoisted_6 = { class: "fl-row" }
const _hoisted_7 = {
  key: 0,
  style: {"color":"#f56c6c"}
}
const _hoisted_8 = {
  key: 1,
  style: {"color":"#67c23a"}
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 0,
  class: "fl-column"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_form = _resolveComponent("app-form")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_app_operates = _resolveComponent("app-operates")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_action_modal = _resolveComponent("action-modal")!
  const _component_export_modal = _resolveComponent("export-modal")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { class: "utils__pageContainer withRouteTab benefitApproveList" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: { name: 'benefit.approve.list' } }, {
            default: _withCtx(() => [
              _createTextVNode("待审批")
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, { to: { name: 'benefit.list' } }, {
            default: _withCtx(() => [
              _createTextVNode("全部")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _createVNode(_component_app_form, {
          "search-list": _ctx.searchList,
          "search-data": _ctx.filterParams,
          onHandleSearch: _ctx.onRefreshTable
        }, null, 8, ["search-list", "search-data", "onHandleSearch"]),
        _withDirectives(_createVNode(_component_app_operates, { mg: "10px 0" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.currPageCheck,
              "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currPageCheck) = $event)),
              onChange: _ctx.changeCurrPageCheck
            }, {
              default: _withCtx(() => [
                _createTextVNode("本页全选")
              ]),
              _: 1
            }, 8, ["checked", "onChange"]),
            _createVNode(_component_a_checkbox, {
              checked: _ctx.checkAll,
              "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkAll) = $event))
            }, {
              default: _withCtx(() => [
                _createTextVNode("全选")
              ]),
              _: 1
            }, 8, ["checked"]),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode("共选中"),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.checkAll ? _ctx.tableTotal : _ctx.selectState.selectedRows.length), 1),
              _createTextVNode("条记录")
            ]),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.batchAudit
            }, {
              default: _withCtx(() => [
                _createTextVNode("批量审核")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              style: {"margin-left":"10px"},
              type: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleOperate('auditExport')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("导出")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.isApproveView]
        ]),
        _withDirectives(_createVNode(_component_app_operates, { mg: "10px 0" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleOperate('allExport')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("导出")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 512), [
          [_vShow, !_ctx.isApproveView]
        ]),
        _createVNode(_component_app_table, {
          ref: "tableRef",
          size: "default",
          "row-key": "id",
          scroll: { x:'100%', y: 700 },
          columns: _ctx.tableColumns,
          data: _ctx.loadTableData,
          "show-pagination": true,
          "row-selection": _ctx.selectState
        }, {
          departmentsStr: _withCtx(({ record }) => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(record.departmentsStr || '---'), 1)
          ]),
          "order-status": _withCtx(({ record }) => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("i", {
                class: "status-dot",
                style: _normalizeStyle({ backgroundColor: _ctx.statusColorMap[record.orderStatus] })
              }, null, 4),
              _createElementVNode("span", null, _toDisplayString(_ctx.orderStatusMap[record.orderStatus] || '未知'), 1)
            ])
          ]),
          tagException: _withCtx(({ record }) => [
            (record.tagException)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(record.tagException), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, "正常"))
          ]),
          action: _withCtx(({ record }) => [
            (record.approveStatus === 0 && record.status === 2 && record.showApprovalFlag)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  onClick: ($event: any) => (_ctx.handleOperate('approve', record.id ))
                }, "审批", 8, _hoisted_9))
              : _createCommentVNode("", true),
            (!(record.approveStatus === 0 && record.status === 2))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  href: "#",
                  onClick: ($event: any) => (_ctx.handleOperate('view', record.id ))
                }, "详情", 8, _hoisted_10))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["columns", "data", "row-selection"])
      ]),
      _createVNode(_component_action_modal, {
        ref: "actionModalRef",
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["onComplete"]),
      _createVNode(_component_export_modal, {
        ref: "exportModalRef",
        onComplete: _ctx.onRefreshTable
      }, null, 8, ["onComplete"]),
      _createVNode(_component_a_modal, {
        visible: _ctx.visible,
        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visible) = $event)),
        title: "批量审批确认",
        "ok-text": "提交审批",
        onOk: _ctx.handleOk
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", null, "对选中的" + _toDisplayString(_ctx.checkAll ? _ctx.tableTotal : _ctx.selectState.selectedRows.length) + "条记录执行", 1),
          _createVNode(_component_a_radio_group, {
            value: _ctx.approval,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.approval) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_radio, {
                style: _normalizeStyle(_ctx.radioStyle),
                value: 1
              }, {
                default: _withCtx(() => [
                  _createTextVNode("批量审核通过")
                ]),
                _: 1
              }, 8, ["style"]),
              _createVNode(_component_a_radio, {
                style: _normalizeStyle(_ctx.radioStyle),
                value: 2
              }, {
                default: _withCtx(() => [
                  _createTextVNode("批量驳回")
                ]),
                _: 1
              }, 8, ["style"])
            ]),
            _: 1
          }, 8, ["value"]),
          (_ctx.approval === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.canResubmit,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.canResubmit) = $event)),
                  options: [{ label: '允许重复提交', value: true }, { label: '不允许重复提交', value: false }],
                  style: {"padding":"5px 0"}
                }, null, 8, ["value"]),
                _createVNode(_component_a_textarea, {
                  value: _ctx.reason,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.reason) = $event)),
                  placeholder: "驳回原因",
                  maxlength: 20
                }, null, 8, ["value"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["visible", "onOk"])
    ]),
    _: 1
  }))
}