
import { message } from 'ant-design-vue';
import { defineComponent, reactive, ref, watch, computed, getCurrentInstance, onMounted } from 'vue';
import moment, { Moment } from 'moment';
import { noEmptyProp, not, settleTree, formatWithIntl } from '@/utils/illuminate';
import { UnwrapNestedRefs } from '@vue/reactivity';
import { useRoute } from 'vue-router';
import { omit } from 'lodash';
import { useStore } from 'vuex';
import { createApiUrl, toLocalUnit } from '@/utils/utils';
import type { TableColumn } from '@/components/Table';
import { request } from '@/utils/request';
import { useCurrentEnterpriseHook } from '@/store';
import { formatDate } from '@/core/filters';
import ActionModal from './ActionModal.vue';
import ExportModal from './ExportModal.vue';

const orderStatusMap: Record<string, string> = {
  '-1': '无效',
  0: '待支付',
  1: '已支付',
  2: '退款中',
  3: '部分支付',
  4: '订单取消',
  5: '已退款',
  6: '部分退款',
  7: '退款失败',
};

const statusColorMap: Record<string, string> = {
  1: '#4aab0d',
  2: '#fb881e',
  5: '#333333',
  7: '#f4271c',
};

export default defineComponent({
  name: 'OilListView',
  components: {
    ActionModal,
    ExportModal,
  },
  setup(props, ctx) {
    const instance = getCurrentInstance()?.proxy;
    const store = useStore();
    useCurrentEnterpriseHook(store.state.User.authority === 0 ? '' : store.state.User.enterprise.enterpriseId);
    const authority = ref(store.state.User.authority);
    const route = useRoute();
    const isApproveView = computed(() => route.name === 'benefit.approve.list'); // 待审批
    const currPageCheck = ref(false);
    const checkAll = ref(false);
    const getDefaultFilterParams = () => ({
      userNameOrPhone: '',
      departmentId: undefined,
      dateRange: [],
      approveStatus: '',
      clockInStatus: '',
      businessNo: '',
      licensePlate: '',
      approveUserOrPhone: '',
      declaredMile: '',
    });

    const filterParams = reactive<{
      userNameOrPhone: string; // 员工姓名
      departmentId: number | undefined;
      approveStatus?: string | number; // 审批：0-待审批 1-通过 2-驳回
      clockInStatus?: string | number; // 打卡情况 1-正常，2-异常情况
      businessNo: string, // 行程单号
      licensePlate: string,
      approveUserOrPhone: string; // 审批人姓名或电话
      dateRange: Moment[],
      declaredMile: boolean | string
    }>({
      ...getDefaultFilterParams(),
    });

    const tableRef = ref<any>(null);
    let selectState: UnwrapNestedRefs<{
      selectedRowKeys: any[];
      selectedRows: any[];
      hideDefaultSelections: boolean;
      onChange: (selectedRowKeys: any[], selectedRows: any[]) => void,
    }>;
    const onSelectChange = (selectedRowKeys: any[], selectedRows: any[]) => {
      selectState.selectedRows = selectedRows;
      selectState.selectedRowKeys = selectedRowKeys;
    };
    selectState = reactive({
      selectedRowKeys: [],
      selectedRows: [],
      hideDefaultSelections: true,
      onChange: onSelectChange,
    });
    const tableList = ref<any[]>([]);
    const tableTotal = ref();
    const queryParams = ref({}); // 查询条件
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      Object.assign(filterParams, { approveStatus: isApproveView.value ? 0 : filterParams.approveStatus });
      const params: any = {
        ...parameter,
        ...noEmptyProp(not(filterParams, ['payTime', 'createTime'])),
        orderType: 1,
        searchLevel: !store.state.User.currentEnterpriseId ? 1 : 0, // searchLevel 0的话是查当前企业，1-查自身及下级企业，2-查自身及上级企业
        departmentIdList: filterParams.departmentId ? [filterParams.departmentId] : [],
      };
      if (filterParams.dateRange && filterParams.dateRange.length) {
        params.startTime = filterParams.dateRange[0].format('YYYY-MM-DD');
        params.endTime = filterParams.dateRange[1].format('YYYY-MM-DD');
      }
      queryParams.value = params;
      request.post(createApiUrl('/newlinkSass/itinerary/approve/list'), omit(params, ['dateRange']))
        .then((res: any) => {
          tableList.value = res.data.list || [];
          tableTotal.value = res.data.total || 0;
          resolve(res.data);
        }).catch((e: any) => {
          message.error(e.message);
        });
    });

    const onRefreshTable = (flag = true) => {
      currPageCheck.value = false;
      tableRef.value.refresh(flag);
    };

    const tableColumns = computed<TableColumn[]>(() => {
      const basic: TableColumn[] = [
        { title: '行程单号', dataIndex: 'businessNo', width: '200px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '提交时间', dataIndex: 'createTime', width: '150px', align: 'center', customRender: ({ text }) => formatDate(text) },
        { title: '公司', dataIndex: 'enterpriseName', width: '130px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '部门', slots: { customRender: 'departmentsStr' }, align: 'center', width: 130 },
        { title: '员工信息', dataIndex: 'userName', width: '150px', align: 'center', customRender: ({ text, record }) => (text ? <div><div>{record.userName}</div><div>{record.userPhone}</div></div> : '---') },
        { title: '行程信息', dataIndex: 'originName', width: '220px', customRender: ({ text, record }) => (<div><div>起：{record.originName || '--'}</div><div>终：{record.destinationName || '--'}</div></div>) },
        { title: '行程开始时间', dataIndex: 'beginTime', width: '220px', customRender: ({ text }) => formatDate(text) || '---' },
        { title: '行程结束时间', dataIndex: 'endTime', width: '220px', customRender: ({ text }) => formatDate(text) || '---' },
        { title: '总里程', dataIndex: 'totalDistanceKm', width: '120px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '员工申报里程', dataIndex: 'proofDistanceKm', width: '120px', align: 'center' },

        { title: '油补金额', dataIndex: 'supplementAmount', width: '120px', align: 'center', customRender: ({ text }) => formatWithIntl(toLocalUnit(text)) },
        { title: '补贴周期', dataIndex: 'subsidiesCycle', width: '120px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '当月上限', dataIndex: 'supplementLimitAmount', width: '120px', align: 'center', customRender: ({ text }) => formatWithIntl(toLocalUnit(text)) },
        { title: '可发放金额', dataIndex: 'availableAmount', width: '120px', align: 'center', customRender: ({ text }) => formatWithIntl(toLocalUnit(text)) },
        { title: '车辆名称', dataIndex: 'vehicleName', width: '120px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '车牌号码', dataIndex: 'licensePlate', width: '150px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '油补途径', dataIndex: 'subsidyWayDesc', width: '150px', align: 'center', customRender: ({ text }) => text || '---' },
        { title: '车类型', dataIndex: 'vehicleType', width: '150px', align: 'center', customRender: ({ text }) => (text === 0 ? '公车' : text === 1 ? '私车' : '---') },
        { title: '打点情况', dataIndex: 'tagException', width: '120px', align: 'center', slots: { customRender: 'tagException' } },
        { title: '操作', dataIndex: 'action', width: 100, slots: { customRender: 'action' }, fixed: 'right' },
      ];
      if (!isApproveView.value) {
        basic.splice(6, 0, { title: '状态', dataIndex: 'approveStatus', width: '120px', align: 'center', customRender: ({ text }) => (text === 0 ? '待审批' : text === 1 ? '已完成' : '驳回') },
          { title: '审批人', dataIndex: 'approveUser', width: '120px', align: 'center', customRender: ({ text, record }) => `${record.approveUser || '---'}${record.approvePhone ? `-${record.approvePhone}` : ''}` },
          { title: '审批时间', dataIndex: 'approveTime', width: '120px', align: 'center', customRender: ({ text }) => formatDate(text) || '---' });
      }
      if (store.state.User.enterprise.channelSource === 'huolala') {
        basic.splice(4, 0, { title: '是否跨城', dataIndex: 'hasCrossCity', width: '120px', customRender: ({ text }) => (text ? '是' : '否'), align: 'center' },
          { title: '从家出发', dataIndex: 'departureIsHome', width: '120px', customRender: ({ text }) => (text ? '是' : '否'), align: 'center' },
          { title: '在家结束', dataIndex: 'destinationIsHome', width: '120px', customRender: ({ text }) => (text ? '是' : '否'), align: 'center' },
          { title: '用车事由', dataIndex: 'reasons', width: '100px', customRender: ({ text }) => text || '---', align: 'center' },
          { title: '费用承担部门', dataIndex: 'bearingDepartmentName', customRender: ({ text }) => text || '---', width: '100px', align: 'center' },
          { title: '过路费（元）', dataIndex: 'tollAmount', width: '100px', customRender: ({ text }) => formatWithIntl(toLocalUnit(text || 0)), align: 'center' },
          { title: '停车费（元）', dataIndex: 'parkingAmount', width: '100px', customRender: ({ text }) => formatWithIntl(toLocalUnit(text || 0)), align: 'center' });
      }
      return basic;
    });
    // 本页全选
    const changeCurrPageCheck = () => {
      selectState.selectedRows = currPageCheck.value ? tableList.value : [];
      selectState.selectedRowKeys = currPageCheck.value ? tableList.value.map(item => item.id) : [];
    };
    const visible = ref(false);
    const reason = ref('');
    const approval = ref(1); // 1 通过 2驳回
    const canResubmit = ref(true);
    watch(visible, val => {
      approval.value = 1;
      reason.value = '';
      canResubmit.value = true;
    });
    // 批量审核
    const batchAudit = () => {
      if ((!selectState.selectedRowKeys.length && !checkAll.value) || !tableList.value.length) return message.info('请选择需要审批的行程单');
      visible.value = true;
    };
    // 批量审批确认
    const handleOk = () => {
      if (approval.value === 2 && !reason.value) return message.warning('驳回原因必填');
      let params = {};
      if (checkAll.value) {
        params = {
          approveAll: 1, // 是否审批所有 1-所有 0-非所有
          approveStatus: approval.value,
          reason: reason.value,
          canResubmit: canResubmit.value,
          itineraryApproveQueryReq: queryParams.value,
        };
      } else {
        params = {
          approveAll: 0, // 是否审批所有 1-所有 0-非所有
          ids: selectState.selectedRowKeys,
          approveStatus: approval.value,
          reason: reason.value,
          canResubmit: canResubmit.value,
        };
      }
      request.post(createApiUrl('/newlinkSass/itinerary/approve/review'), params)
        .then((res: any) => {
          visible.value = false;
          onRefreshTable(false);
        }).catch((e: any) => {
          message.error(e.message);
          if (e.code && e.code === 900) {
            visible.value = false;
            onRefreshTable(false);
          }
        });
    };
    const radioStyle = reactive({
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    });
    /* = -------------------------------------------------- = 加载搜索用的部门树 = -------------------------------------------------- = */
    const departmentList = ref<any[]>([]);
    const loadEnterpriseDepartment = async (val:any) => {
      filterParams.departmentId = undefined; // 清空部门
      // 公司查询全部 部门为空
      if (val === '' || val === undefined) {
        departmentList.value = [];
        return;
      }
      let res: any;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'));
      } catch (e: Error & any) {
        return message.error(e.message);
      }
      departmentList.value = settleTree(res.data.departmentList,
        { key: 'id', title: 'departmentName', value: 'id' },
        'departmentList');
    };

    const searchList = computed(() => {
      const common = [
        { 
          label: '员工',
          name: 'userNameOrPhone',
          type: 'input',
          allowClear: true,
          placeholder: '员工姓名/手机号码',
        },
        { 
          label: '公司',
          name: 'currentEnterpriseId',
          type: 'currentEnterprise',
          withDefaultAll: authority.value === 0,
        },
        { 
          label: '部门',
          name: 'departmentId',
          type: 'tree_select',
          allowClear: true,
          showSearch: true,
          showLine: true,
          placeholder: '部门名称',
          searchPlaceholder: '请选择', 
          treeNodeFilterProp: 'title',
          treeData: departmentList.value,
        },
        { 
          label: '打点情况',
          name: 'clockInStatus',
          type: 'select',
          placeholder: '打点情况',
          allowClear: true, 
          fieldNames: { label: 'label', value: 'value' },
          sel_data: [
            { label: '全部', value: '' },
            { label: '正常', value: 1 },
            { label: '异常情况', value: 2 },
          ],
        },
        { 
          label: '申报里程',
          name: 'declaredMile',
          type: 'select',
          placeholder: '申报里程',
          allowClear: true, 
          fieldNames: { label: 'label', value: 'value' },
          sel_data: [
            { label: '全部', value: '' },
            { label: '有', value: true },
            { label: '无', value: false },
          ],
        },
        { 
          label: '行程单号',
          name: 'businessNo',
          type: 'input',
          allowClear: true,
          placeholder: '请输入行程单号',
        }, 
        { 
          label: '车牌号',
          name: 'licensePlate',
          type: 'input',
          placeholder: '请输入车牌号',
          allowClear: true, 
        },
      ];
      const extra = [
        { 
          label: '审批人',
          name: 'approveUserOrPhone',
          type: 'input',
          allowClear: true,
          placeholder: '员工姓名/手机号码',
        },
        { 
          label: '状态',
          name: 'approveStatus',
          type: 'select',
          placeholder: '状态',
          allowClear: true, 
          fieldNames: { label: 'label', value: 'value' },
          sel_data: [
            { label: '全部', value: '' },
            { label: '待审批', value: 0 },
            { label: '已报销', value: 1 },
            { label: '驳回', value: 2 },
          ],
        },
        { 
          label: '日期',
          name: 'dateRange',
          type: 'rangepicker',
          format: 'YYYY-MM-DD',
          placeholder: ['起始时间', '结束时间'],
          width: '230px',
        },
      ];
      if (isApproveView.value) return common;
      return [...common, ...extra];
    });

    watch(() => store.state.User.currentEnterpriseId, () => {
      if (store.state.User.authority === 0) {
        loadEnterpriseDepartment(store.state.User.currentEnterpriseId);
      } else if (store.state.User.authority === 1) {
        loadEnterpriseDepartment(store.state.User.currentEnterpriseId);
      }
    });

    const sign = ref(false);
    watch(() => route.path, val => {
      if (val === '/benefit/list' && !sign.value) instance?.$sensors.track('es_saas_itinerary_page_view', { page_name: '行程单_全部页_浏览' });
      sign.value = true;
      Object.assign(filterParams, getDefaultFilterParams());
      onRefreshTable();
      if (store.state.User.authority === 0) {
        loadEnterpriseDepartment('');
      }
    });

    /** --------------------------------------------------------详情--------------------------------------------------------- */
    const actionModalRef = ref();
    const exportModalRef = ref();
    const handleOperate = (type: string, id?: number) => {
      if (type === 'auditExport' || type === 'allExport') {
        exportModalRef.value.handle(type);
        return;
      }
      actionModalRef.value.handle(type, id);
    };

    onMounted(() => {
      instance?.$sensors.track('es_saas_itinerary_verify_page_view', { page_name: '行程单_待审批页_浏览' });
    });

    return {
      authority,

      departmentList,

      reason,
      canResubmit,
      handleOk,
      approval,
      radioStyle,
      visible,
      batchAudit,
      tableTotal,
      changeCurrPageCheck,
      orderSourceList: [
        { label: '全部', value: '' },
        { label: '员工余额支付', value: '2' },
      ],
      selectState,
      onSelectChange,
      orderStatusMap,
      statusColorMap,
      filterParams,
      isApproveView,
      tableColumns,
      loadTableData,
      tableRef,
      onRefreshTable,
      currPageCheck,
      checkAll,
      searchList,
      actionModalRef,
      exportModalRef,
      handleOperate,
    };
  },
});
