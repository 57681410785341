
import { message } from 'ant-design-vue';
import { reactive, ref, defineComponent, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter, onBeforeRouteLeave, useRoute } from 'vue-router';
import type { TableColumn } from '@/components/Table';
import { Moment } from 'moment';
import { createApiUrl, sldPageTitle, dealEmpty } from '@/utils/utils';
import { request } from '@/utils/request';

export default defineComponent({
  name: 'ApproveManage',
  setup(props, ctx) {
    const router = useRouter();
    const searchList = ref([
      { 
        label: '公司',
        name: 'currentEnterpriseId',
        type: 'currentEnterprise',
        disableInOpen: true,
        withDefaultAll: true,
      },
      { 
        label: '员工',
        name: 'account',
        type: 'member',
        placeholder: '员工',
        status: 'ALL',
      },
      { 
        label: '审批单号',
        name: 'approvalNo',
        type: 'input',
        allowClear: true,
        placeholder: '审批单号',
      },
      { 
        label: '提交日期',
        name: 'createTime',
        type: 'rangepicker',
        format: 'YYYY-MM-DD',
        placeholder: ['起始时间', '结束时间'],
        width: '250px',
      },
    ]);

    const defaultFilterParams = {
      account: undefined,
      approvalNo: undefined,
      createTime: [],
    };
    const filterParams = reactive<{
      account: string | undefined;
      approvalNo: string | undefined;
      createTime: Moment[];
    }>({
      ...defaultFilterParams,
    });

    /** -------------------------------------------------表格--------------------------------------------------------- */
    const tableRef = ref<any>(null);
    const tableColumns: TableColumn[] = [
      { title: '审批单号', dataIndex: 'approvalNo', width: '220px', align: 'center' },
      { title: '提交时间', dataIndex: 'createTime', width: '160px', align: 'center' },
      { title: '员工信息', dataIndex: 'userName', width: '120px', align: 'center', slots: { customRender: 'userName' } },
      { title: '行程单数量', dataIndex: 'itineraryNum', width: '150px', align: 'center' },
      { title: '油补金额（元）', dataIndex: 'oilSubsidyAmount', customRender: ({ text }) => (text || 0).toFixed(2), width: '120px', align: 'center' },
      { title: '过路费金额（元）', dataIndex: 'tollAmount', customRender: ({ text }) => (text || 0).toFixed(2), width: '120px', align: 'center' },
      { title: '停车费金额（元）', dataIndex: 'parkingAmount', customRender: ({ text }) => (text || 0).toFixed(2), width: '100px', align: 'center' },
      { title: '总金额（元）', dataIndex: 'totalAmount', customRender: ({ text }) => (text || 0).toFixed(2), width: '100px', align: 'center' },
      { title: '操作', dataIndex: 'action', width: '100px', slots: { customRender: 'action' }, fixed: 'right', align: 'center' },
    ];
    // 加载数据方法 必须为 Promise 对象
    const loadTableData = async (parameter: any) => new Promise<void>((resolve, reject) => {
      try {
        const params: any = dealEmpty({ ...parameter, ...filterParams });
        if (filterParams.createTime && filterParams.createTime.length) {
          params.createTimeStart = filterParams.createTime[0].format('YYYY-MM-DD 00:00:00');
          params.createTimeEnd = filterParams.createTime[1].format('YYYY-MM-DD 23:59:59');
        }
        delete params.createTime;

        request.post(createApiUrl('/newlinkSass/itinerary/queryItineraryApprovalForm'), params)
          .then((res: any) => {
            resolve(res.data);
          })
          .catch((e: Error & any) => message.error(e.message));
      } catch (e: Error & any) {
        return message.error(e.message);
      }
    });

    // 查询
    const onRefreshTable = async (val?: any) => {
      tableRef.value.refresh(true);
    };

    // 审批
    const onDetail = (record: any) => {
      sessionStorage.setItem('approveRecord', JSON.stringify(record));
      router.push({ name: 'benefit.approve.manage.detail', query: { id: record.id } });
    };
    const route = useRoute();
    watch(() => route.path, (newVal, oldVal) => {
      if (newVal === oldVal) router.go(-1);
    }, { deep: true });

    onBeforeRouteLeave((to, form, next) => {
      if (to.name !== 'benefit.approve.manage.detail') {
        sessionStorage.removeItem('approveRecord');
      }
      next();
    });

    return {
      searchList,
      filterParams,
      tableRef,
      tableColumns,
      sldPageTitle,
      loadTableData,
      onRefreshTable,
      onDetail,
    };
  },
});
