import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_sld_modal = _resolveComponent("app-sld-modal")!

  return (_openBlock(), _createBlock(_component_app_sld_modal, {
    ref: "sldModalRef",
    visible: _ctx.visible,
    title: "导出行程单",
    width: "50%",
    "label-width": "180px",
    content: _ctx.content,
    submiting: _ctx.exporting,
    onSldHandleCancle: _ctx.sldHandleCancle,
    onSldHandleConfirm: _ctx.sldHandleConfirm
  }, null, 8, ["visible", "content", "submiting", "onSldHandleCancle", "onSldHandleConfirm"]))
}